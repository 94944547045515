import React from 'react'

import {
  Bullet,
  Button,
  Buttons,
  Columns,
  Divider,
  FormGroup,
  FormLayout,
  FormLayoutItem,
  Heading,
  Inline,
  List,
  ListItem,
  Main,
  Message,
  ModalBody,
  ModalContent,
  Paragraph,
  QuestionCircleIcon,
  Spacer,
  Spinner,
  Textarea,
  ThumbDownIcon,
  ThumbUpIcon,
  XIcon
} from '@te-digi/styleguide'

import { Content } from '../../components/Content'
import { NoteItem } from '../../components/NoteItem'
import { PatternExampleLaheta } from '../../examples/react/PatternExampleLaheta'
import { Section } from '../../components/Section'
import { SectionHeading } from '../../components/SectionHeading'
import { ZoomedExample } from '../../components/ZoomedExample'
import { Link } from '../../components/Link'
import { LinkMock } from '../../components/LinkMock'
import { Playground } from '../../components/Playground'
import { Code } from '../../components/Code'
import { PatternExampleFailLaheta } from '../../examples/react/PatternExampleToimintoFail'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content heading="Lähetä-toiminto">
    <Playground
      enableOverflow
      example={PatternExampleLaheta}
    />
    <Section title="Käyttötarkoitukset">
      <Message attentionColor="success">
        <Heading
          icon={
            <ThumbUpIcon
              color="success"
              size="lg"
            />
          }
          level={3}
          size={5}
        >
          Käytetään
        </Heading>
        <Paragraph>
          Näkymissä joissa käyttäjä tarkistaa ja/tai muokaa tietoja ja lähettää
          lähettää ne toisen käyttäjän (esim. asiakkaan tai asiantitunijan)
          nähtäväksi, tehtäväksi tai käsiteltäväksi (esim. suunnitelman
          hyväksyminen).
        </Paragraph>
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <strong>Lomakkeilla</strong> (esim. asiakkaan viranomaisasioinnissa
            asiakas lähettää lomakkeen asiantuntijoille Lähetä-toiminnolla).
          </ListItem>
          <ListItem>
            <strong>Dialogeissa</strong> (esim. TMT palautteen lähetysdialogi ja
            työnantajan yhteydentotto työntekijä)
          </ListItem>
          <ListItem>
            <strong>Käsittelynäkymissä</strong> (esim. selvityspyyntö, lausunto,
            suunnitelma).
          </ListItem>
        </List>
      </Message>
      <Message attentionColor="danger">
        <Heading
          icon={
            <ThumbDownIcon
              color="danger"
              size="lg"
            />
          }
          level={3}
          size={5}
        >
          Ei käytetä
        </Heading>
        <List
          noMargin
          variant="unordered"
        >
          <ListItem>
            <strong>Lomakkeilla</strong>, jos
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Jos tietoja voi muokata tallentamisen jälkeen. Sen sijaan
                käytettävä toimintopainike on <Link page="Tallenna-toiminto" />
              </ListItem>
              <ListItem>
                Jos tiedot julkaistaan yleisesti nähtäväksi, ja tietoja on voi
                muokata tallentamisen jälkeen (esim. työpaikkailmoitus tai
                palvelu). Sen sijaan käytettävä toimintopainike on{' '}
                <Link page="Julkaise-toiminto" />
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>Käsittelynäkymissä</strong>
          </ListItem>
        </List>
      </Message>
    </Section>
    <Section title="Periaatteet">
      <Heading
        icon={
          <Bullet
            color="danger"
            icon={<ThumbDownIcon color="white" />}
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Tietojen muokkaaminen: ei
      </Heading>
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          <strong>Käyttäjä ei voi muokata lähetettyjä tietoja.</strong> Lähetä
          on kertaluonteinen toiminto.
        </ListItem>
        <ListItem>
          Asiantuntija voi käsitellä ja hallinnoida tietoihin liittyvää asiaa
          käsittelynäkymässä, mutta jos tietoja muokataan, ne on lähetettävä
          uudelleen.
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="success"
            icon={<ThumbUpIcon color="white" />}
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Tietojen validointi: kyllä
      </Heading>
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          Lomakkeella syötetyt tiedot <strong>validoidaan aina</strong>{' '}
          toiminnon suorittamisen yhteydessä.
        </ListItem>
        <ListItem>
          Validointivirheistä ilmoitetaan käyttäjälle{' '}
          <Link page="Lomakkeen virheenkäsittely" /> -patternin mukaisesti
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="success"
            icon={<ThumbUpIcon color="white" />}
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Toiminnon varmistus: kyllä
      </Heading>
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          Toiminnon suorittaminen <strong>varmistetaan aina</strong> käyttäjältä
          varmistusdialogilla (
          <Code>
            <Link page="Modal" />
          </Code>
          )
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="success"
            icon={<ThumbUpIcon color="white" />}
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Palaute toiminnon onnistumisesta: kyllä
      </Heading>
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          Toiminnon onnistumisesta <strong>ilmoitetaan aina</strong> käyttäjälle
          tapauksesta riippuen joko
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              <strong>Väliaikaisella ilmoituksella</strong> (
              <Code>
                <Link page="Notifications" />, status='success'
              </Code>
              , esim. asiantuntijan käsittelynäkymissä). Viestin teksti voi olla
              geneerinen: &quot;Tiedot lähetettiin onnistuneesti&quot;,
              tarkentaa lähetetyn tiedon tyypin tai tarjota tarkempaa
              kontekstikohtaista tietoa.
            </ListItem>
            <ListItem>
              <strong>Kiitosmodaalilla</strong> (
              <Code>
                <Link page="Modal" />
              </Code>
              , esim. asiakkaan viranomaisasiointi). Kts.{' '}
              <a href="#tilat-ja-muunnelmat">Tilat ja muunnelmat</a>.
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="warning"
            icon={<QuestionCircleIcon color="white" />}
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Palaute toiminnon viivästymisestä: tarvittaessa
      </Heading>
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          Toiminnon viivästymisestä <strong>ilmoitetaan tarvittaessa</strong>{' '}
          käyttäjälle Tietoja lähetetään -modaalilla (
          <Code>
            <Link page="Modal" />
          </Code>
          ) Kts. <a href="#tilat-ja-muunnelmat">Tilat ja muunnelmat</a>.
        </ListItem>
      </List>
      <Divider />
      <Heading
        icon={
          <Bullet
            color="success"
            icon={
              <ThumbUpIcon
                color="white"
                size="md"
              />
            }
            size="lg"
          />
        }
        level={3}
        size={5}
      >
        Palaute toiminnon epäonnistumisesta: kyllä
      </Heading>
      <List
        noMargin
        variant="unordered"
      >
        <ListItem>
          Toiminnon epäonnistumisesta <strong>ilmoitetaan aina</strong>{' '}
          käyttäjälle
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              <strong>
                Julkaisun yhteydessä ilmenneestä validointivirheestä
              </strong>{' '}
              ilmoitetaan näkymään lisättävällä varoituksella (
              <Code>
                <Link page="Alert" />
              </Code>
              ). Tarkempi ohjeistus <Link page="Lomakkeen virheenkäsittely" />{' '}
              -patternissa
            </ListItem>
            <ListItem>
              <strong>
                Lähettämisen estävästä välittömästä järjestelmävirheestä
              </strong>{' '}
              (esim. ei verkkoyhteyttä) ilmoitetaan varoitusdialogilla (
              <Code>
                <Link page="Modal" />
              </Code>
              ).Katso esimerkki kohdasta{' '}
              <a href="#tilat-ja-muunnelmat">Tilat ja muunnelmat</a>.
            </ListItem>
            <ListItem>
              <strong>
                Vastaanottavan palvelimen tai taustajärjestelmä palauttamasta
                virheestä
              </strong>{' '}
              ilmoitetaan käyttäjälle tapauskohtaisesti, esim.
              <List
                noMargin
                variant="unordered"
              >
                <ListItem>
                  <strong>Varoitusdialogilla</strong> (
                  <Code>
                    <Link page="Modal" />
                  </Code>
                  )
                </ListItem>
                <ListItem>
                  <strong>Väliaikaisella ilmoituksella</strong> (
                  <Code>
                    <Link page="Notifications" />, status="error"
                  </Code>
                  )
                </ListItem>
                <ListItem>
                  Tietoja päivitetään -tilaisella{' '}
                  <strong>kiitosmodaalilla</strong>, ja modaalin sulkemisen
                  jälkeen tapahtumavirralle lisättävällä varoituksella (
                  <Code>
                    <Link page="Alert" />
                  </Code>
                  ) (tarkemmin kiitosmodaalista ja vastaanottoilmoituksesta kts.{' '}
                  <a href="#tilat-ja-muunnelmat">Tilat ja muunnelmat</a>)
                </ListItem>
              </List>
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Divider />
      <Heading
        level={3}
        size={5}
      >
        Liittyvät toiminnot
      </Heading>
      <List variant="unordered">
        <ListItem>
          <strong>
            Lähettämisen vaihtoehtona tarjotaan aina jokin keskeyttävä toiminto:
          </strong>
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              <Link page="Jatka myöhemmin -toiminto" />
            </ListItem>
            <ListItem>
              <Link page="Keskeytä-toiminto" />
            </ListItem>
            <ListItem>Peruuta-toiminto</ListItem>
          </List>
        </ListItem>
      </List>
    </Section>
    <Section title="Rakenne ja toiminnallisuus">
      <Playground
        enableOverflow
        example={PatternExampleLaheta}
      />
      <NoteItem
        bullet="1"
        title={
          <>
            Painike (
            <Code>
              <Link page="Button" />, color='secondary'
            </Code>
            )
          </>
        }
      >
        <Paragraph>
          Painikkeesta avautuu varmistusdialogi (
          <Code>
            <Link page="Modal" />
          </Code>
          ), josta käyttäjä hyväksyy tai peruuttaa toiminnon suorittamisen.
        </Paragraph>
        <Paragraph noMargin>
          Geneerisen Lähetä-tekstin lisäksi painikkeessa voidaan tarvittaessa
          esim.
        </Paragraph>
        <List variant="unordered">
          <ListItem>
            tarkentaa lähetettävän tiedon tyyppi (esim. Lähetä ilmoitus),
          </ListItem>
          <ListItem>
            tarkentaa mihin tiedot lähetetään (esim. Lähetä hyväksyttäväksi),
          </ListItem>
          <ListItem>
            vaihtaa Lähetä paremmin kontekstiin sopivaan verbiin (esim.
            asiakkaan asioinnissa &quot;Siirrä palautuspäivää&quot; ja
            &quot;Päätä työnhaku&quot;).
          </ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="2"
        title={
          <>
            Varmistusdialogi (
            <Code>
              <Link page="Modal" />
            </Code>
            )
          </>
        }
      >
        <List variant="unordered">
          <ListItem>
            <strong>Otsikko</strong>
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Varmistus toiminnon suorittamisesta kysymysmuodossa.
              </ListItem>
              <ListItem>
                Oletuksena kysymyksessä täsmennetään julkaistavan tiedon tyyppi.
                Esimerkiksi: &quot;Lähetetäänkö ilmoitus?&quot;
              </ListItem>
              <ListItem>
                Geneeristä muotoilua &quot;Lähetetäänkö tiedot?&quot; käytetään,
                jos tiedon tyyppiä ei ole mahdollista täsmentää.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>Teksti</strong>
            <List
              noMargin
              variant="unordered"
            >
              <ListItem>
                Tarkentavaa lisätietoa toiminnon hyväksymisen yksityiskohdista
                ja seurauksista.
              </ListItem>
              <ListItem>
                Oletuksena tekstissä varoitetaan käyttäjää, että tietoja ei voi
                enää muokata ja mitä lähettämisestä seuraa.
                <List
                  noMargin
                  variant="unordered"
                >
                  <ListItem>
                    Esimerkiksi työnhaun aloitus: &quot;Kun lähetät ilmoituksen,
                    et voi enää muokata sitä. Ilmoitus käsitellään antamiesi
                    vastausten perusteella.&quot;
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                Geneeristä muotoilua &quot;Et voi muokata tietoja lähettämisen
                jälkeen.&quot; käytetään, jos täsmällisempää tietoa ei voida
                tarjota.
              </ListItem>
            </List>
          </ListItem>
          <ListItem>
            <strong>Toiminnot</strong>
            <List variant="unordered">
              <ListItem>
                <strong>Peruuta</strong>-painike (
                <Code>
                  <Link page="Button" />, variant='outline'
                </Code>
                ).
                <List
                  noMargin
                  variant="unordered"
                >
                  <ListItem>
                    Sulkee dialogin ja palauttaa käyttäjän näkymään jossa hän
                    painoi toimintopainiketta.
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                <strong>Lähetä</strong>-painike (
                <Code>
                  <Link page="Button" />, color='secondary'
                </Code>
                ).
                <List
                  noMargin
                  variant="unordered"
                >
                  <ListItem>sulkee dialogin ja suorittaa toiminnon</ListItem>
                  <ListItem>
                    Toiminnon suoritamisen ajaksi painike asetetaan{' '}
                    <Code>loading</Code>
                    -tilaan.
                  </ListItem>
                  <ListItem>
                    Kun lähettäminen on suoritettu dialogi sulkeutuu ja käyttäjä
                    ohjataan erikseen määriteltyyn näkymään (esim. lomaketta
                    edeltäneeseen näkymään, tai tapahtumavirralle).
                  </ListItem>
                </List>
              </ListItem>
            </List>
          </ListItem>
        </List>
      </NoteItem>
      <NoteItem
        bullet="3"
        title={
          <>
            Ilmoitus toiminnon onnistumisesta (
            <Code>
              <Link page="Notifications" />, status='success'
            </Code>
            )
          </>
        }
      >
        <Paragraph noMargin>Ilmoituksen teksti voi olla</Paragraph>
        <List variant="unordered">
          <ListItem>
            geneerinen &quot;Tiedot lähetettiin onnistuneesti&quot;,
          </ListItem>
          <ListItem>tarkentaa tallennetun tiedon tyypin tai</ListItem>
          <ListItem>
            tarjota lisäksi tarkempaa kontekstikohtaista tietoa, kuten
            julkaistun luonnoksen otsikon.
          </ListItem>
        </List>
      </NoteItem>
    </Section>
    <Section title="Tilat ja muunnelmat">
      <SectionHeading>
        Lähetys viivästyy: Tietoja lähetetään -modaali
      </SectionHeading>
      <Paragraph>
        <Code>
          <Link page="Modal" />
        </Code>
      </Paragraph>
      <Paragraph noMargin>
        Jos lähettäminen kestää normaalia pidempään (esim. yli 10 sekuntia),
        varmistusdiagogin tilalle avautuu Tietoja lähetetään -modaali, joka
        varmistaa käyttäjälle prosessin olevan vielä käynnissä (esim. asiakkaan
        asioinnissa).
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Tämä tila voi kestää max 30 sekuntia, jonka jälkeen käyttäjälle
          näytetään <strong>Kiitosmodaali</strong> (kts. alla).
        </ListItem>
        <ListItem>
          Dialogi on geneerinen:
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>Otsikko: “Tietoja lähetetään”</ListItem>
            <ListItem>
              Teksti: &quot;Tietojen lähettäminen on käynnissä. Odota
              hetki.&quot;
            </ListItem>
            <ListItem>
              Tekstin vieressä näytetään{' '}
              <Code>
                <Link page="Spinner" />
              </Code>
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Playground>
        <ModalContent size="lg">
          <ModalBody>
            <Heading
              id="modal-heading"
              size={4}
            >
              Tietoja lähetetään
            </Heading>
            <Inline gapX="md">
              <Spinner />
              <Paragraph noMargin>
                Tietojen lähettäminen on käynnissä. Odota hetki.
              </Paragraph>
            </Inline>
          </ModalBody>
        </ModalContent>
      </Playground>
      <SectionHeading>
        Ilmoitus lähetyksen estävästä järjestelmävirheestä
      </SectionHeading>
      <Paragraph noMargin>
        Lähetyksen estävästä välittömästä järjestelmävirheestä ilmoitetaan
        varoitusdialogilla (
        <Code>
          <Link page="Modal" />
        </Code>
        ).
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Modaalin otsikko on geneerinen: "Lähetys epäonnistui"
        </ListItem>
        <ListItem>
          Modaalin teksti voi olla esim.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              geneerinen &quot;Tietojen lähetys epäonnistui. Yritä
              uudelleen.&quot;,
            </ListItem>
            <ListItem>antaa tarkempaa tietoa epäonnistumisen syystä.</ListItem>
          </List>
        </ListItem>
        <ListItem>
          Sulje-toiminto palauttaa käyttäjän näkymään jossa hän painoi
          toimintopainiketta.
        </ListItem>
      </List>
      <Playground
        enableOverflow
        example={PatternExampleFailLaheta}
      />
      <SectionHeading>
        Onnistunut tietojen lähetys: Kiitosmodaali ja vastaanottoilmoitus-sivu
      </SectionHeading>
      <Paragraph noMargin>
        Asiakkaan viranomaisasioinnissa Lähetä-toiminnon onnistumisesta
        ilmoitetaan erillisellä kiitosmodaalilla ja vastaanottoilmoitus-sivulla
        (esim. Työnhaun aloitus).
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Jos lomakkeen lähettäminen ja vastaanottaminen onnistuu ilman
          viivettä, käyttäjälle voidaan lähetettää vastaanottoilmoitus
          välittömästi.
        </ListItem>
        <ListItem>
          Kiitosmodaalissa ilmoitetaan käyttäjälle lähettämisen onnistuneen ja
          tietojen päivittyneen.
        </ListItem>
      </List>
      <Paragraph>
        <strong>Vastaanottoilmoitus</strong> on oma sivunsa, jossa käyttäjälle
        annetaan lisätietoja ja ohjeita prosessin seuraavista vaiheista.
      </Paragraph>
      <Paragraph noMargin>
        <strong>Kiitosmodaalin</strong> sisältö muodostetaan teknisesti
        vastaanottoilmoituksen sisällöstä:
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          <strong>Modaalin otsikona</strong> käytetään vastaanottosivun
          pääotsikkoa, jossa kiitetään tietojen lähettämisestä ja tarkennetaan
          tarvittaessa mitä tietojen lähettäminen käytännössä tarjoittaa. (esim.
          &quot;Kiitos ilmoituksestasi. Olet ilmoittautunut työnhakijaksi&quot;)
        </ListItem>
        <ListItem>
          <strong>Tekstissä</strong> näytetään ennalta määrätyt
          vastaanottoilmoituksen osat.
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Tekstin lopuksi käyttäjälle tarjotaan Katso lähettämäsi tiedot
              -linkki, josta käyttäjä voi siirtyä suoraan
              vastaanottoilmoitus-näkymään tarkistamaan ilmoittamansa tiedot.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          Kun käyttäjä sulkee modaalin <strong>Sulje-painikkeesta</strong> (
          <Code>
            <Link page="Button" />, variant='outline'
          </Code>
          ), hänet ohjataan Tapahtumavirta-näkymään:
          <List
            noMargin
            variant="unordered"
          >
            <ListItem>
              Tapahtumavirtaan lisätään uusi tapahtuma, josta käyttäjä pääsee
              tarkastelemaan vastaanottoilmoitus-sivua.
            </ListItem>
            <ListItem>
              Tapahtumavirran alussa näytettävään viestilaatikkoon (
              <Code>
                <Link page="Message" />, attentionColor='success'
              </Code>
              ) päivitetään tiedot työnhaun voimassaolosta.
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Playground>
        <ModalContent size="lg">
          <ModalBody>
            <Heading
              id="modal-heading"
              size={4}
            >
              Vastaanottoilmoituksen otsikko
            </Heading>
            <Paragraph>Vastaanottoilmoituksen teksti</Paragraph>
            <Link as={LinkMock}>Katso lähettämäsi tiedot</Link>
            <Buttons
              align="right"
              noMargin
            >
              <Button variant="outline">Sulje</Button>
            </Buttons>
          </ModalBody>
        </ModalContent>
      </Playground>
      <SectionHeading>
        Tietojen lähetys kesken tai epäonnistuu: Kiitosmodaali ja
        vastaanottoilmoitus-sivu
      </SectionHeading>
      <Paragraph>
        <strong>
          Jos tietojen lähettämisessä ja päivittymisessä tapahtumavirralle
          kestää normaalia pidempään
        </strong>{' '}
        (esim. jos yhteyden muodostamisessa taustajärjestelmiin on kestää yli 30
        sekuntia), kiitosmodaalissa ilmoitetaan tietojen päivittymisen olevan
        vielä kesken.
      </Paragraph>
      <Paragraph>
        Tällöin modaalin sisältö poikkeaa onnistuneesta kiitosmodaalista:
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          <strong>Otsikossa</strong> kiitetään tietojen lähettämisestä ja
          ilmoitetaan tietojen päivittymisestä (esim. &quot;Kiitos
          ilmoituksestasi. Tietojasi päivitetään&quot;)
        </ListItem>
        <ListItem>
          <strong>Tekstissä</strong> ilmoitetaan &quot;Ilmoittamiesi tietojen
          päivittyminen palveluun on kesken. Odota hetki ja päivitä sivu.&quot;
        </ListItem>
        <ListItem>
          Kun käyttäjä sulkee modaalin <strong>Sulje-painikkeesta</strong> (
          <Code>
            <Link page="Button" />, variant='outline'
          </Code>
          ), hänet ohjataan Tapahtumavirta-näkymään:
          <List variant="unordered">
            <ListItem>
              Tapahtumavirran alussa näytetään varoitus (
              <Code>
                <Link page="Alert" />
              </Code>
              ), jossa toistetaan kiitosmodaalin teksti &quot;Ilmoittamiesi
              tietojen päivittyminen palveluun on kesken. Odota hetki ja päivitä
              sivu.&quot; ja tarjotaan Päivitä sivu -painike (
              <Code>
                <Link page="Button" />, variant='plain', iconLeft=IconRefresh
              </Code>
              ).
            </ListItem>
            <ListItem>
              Tapahtumavirran alussa näytettävään viestilaatikkoon (
              <Code>
                <Link page="Message" />, attentionColor='warning'
              </Code>
              ) päivitetään tiedot työnhaun voimassaolosta.
            </ListItem>
            <ListItem>
              Tapahtumavirtaan lisätään uusi tapahtuma, jossa hoksautuksella (
              <Code>
                <Link page="Help" />, variant='error'
              </Code>
              ) toistetetan varoituksen teksti &quot;Ilmoittamiesi tietojen
              päivittyminen palveluun on kesken. Odota hetki ja päivitä
              sivu.&quot;
            </ListItem>
          </List>
        </ListItem>
      </List>
      <Paragraph>
        <strong>
          Jos tietojen lähettäminen tai päivitys epäonnistuu lopullisesti
        </strong>{' '}
        (esim. taustajärjestelmä kaatunut eikä vastausta saada 15 min sisään),
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Tapahtumavirta-sivun päivittyessä tapahtumavirran alussa näytetään
          varoitus (
          <Code>
            <Link page="Alert" />
          </Code>
          ).
        </ListItem>
        <ListItem>
          Tapahtumavirralla, tapahtuman hoksautus (
          <Code>
            <Link page="Help" />, variant='error'
          </Code>
          ) muutetaan muotoon: &quot;Ilmoittamiesi tietojen päivitys
          epäonnistui. Ota yhteyttä Henkiköasiakkaan puhelinpalveluun, puh. 0295
          025 500.&quot;
        </ListItem>
      </List>
    </Section>
    <Section title="Sijoittuminen näkymässä">
      <Paragraph noMargin>
        Lomakkeella painike sijoitetaan lähtökohtaisesti sisältöalueen loppuun
        yhdessä muiden toimintopainikkeiden kanssa.
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          Jos näkymässä on useita koko näkymään liittyviä toimintoja,{' '}
          <strong>Lähetä sijoitetaan toiminnoista viimeiseksi.</strong>
        </ListItem>
        <ListItem>
          Sijoittelusta lomakkeella tarkemmin{' '}
          <Link page="Lomakkeen toiminnot" /> -patternissa.
        </ListItem>
      </List>
      <ZoomedExample>
        <Columns layout="single">
          <Main>
            <FormLayout>
              <FormLayoutItem>
                <FormGroup noMargin>
                  <Textarea label="Lomakekenttä" />
                </FormGroup>
              </FormLayoutItem>
            </FormLayout>
            <Spacer marginTop="xl">
              <Buttons align="center">
                <Button
                  iconLeft={<XIcon />}
                  variant="plain"
                >
                  Keskeytä
                </Button>
                <Button
                  color="primary"
                  variant="outline"
                >
                  Tallenna luonnoksena
                </Button>
                <Button
                  color="primary"
                  variant="outline"
                >
                  Esikatsele
                </Button>
                <Button color="secondary">Lähetä</Button>
              </Buttons>
            </Spacer>
          </Main>
        </Columns>
      </ZoomedExample>
    </Section>
    <Section title="Esimerkkejä käyttötapauksista">
      <SectionHeading>Työmarkkinatorilla</SectionHeading>
      <List variant="unordered">
        <ListItem>Henkilöasiakasan viranomaisasiointi</ListItem>
      </List>
      <SectionHeading>Asiantuntijan työmarkkinatorilla</SectionHeading>
      <List variant="unordered">
        <ListItem>
          Selvityspyynnön, lausuntojen ja suunnitelman käsittelynäkymät
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
